import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box, IconButton } from '@mui/material';
import { Sort } from '@mui/icons-material';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TablePagination from '@mui/material/TablePagination';

// class RecordData2(Base2):
//     __tablename__ = "issue_record"
//     id = Column(Integer, primary_key=True)
//     line_id = Column(String)
//     num_issued = Column(Integer)
//     num_left = Column(Integer)
//     line_user_name = Column(String)
//     created_at = Column(DateTime)
//     updated_at = Column(DateTime)

interface Data {
    displayName: string;
    userId: string;
    numIssued: number;
    createdAt: string;
    numLeft: number;
    editableNumIssued: string;
}
const createData = (displayName: string, userId: string, numIssued: number, createdAt: string, num_left: number): Data => {
    return { displayName, userId, numIssued, createdAt, numLeft: num_left, editableNumIssued: num_left.toString() };
};

export default function BasicTable() {
    const [initialRows, setData] = useState<Data[]>([]);
    const [rows, setRows] = useState<Data[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<{ column: string | null; direction: 'asc' | 'desc'; }>({ column: null, direction: 'asc' });
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [editableNumIssued, setEditableNumIssued] = useState<{ [key: string]: string }>({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleNumIssuedChange = (userId: string, newValue: string) => {
        setEditableNumIssued(prev => {
            const newEditableNumIssued = { ...prev, [userId]: newValue };
            setRows(rows.map(row => row.userId === userId ? { ...row, editableNumIssued: newEditableNumIssued[userId] } : row));
            return newEditableNumIssued;
        });
    };

    const fetchData = async (username: string, password: string) => {
        const token = btoa(`${username}:${password}`);
        try {
            const response = await axios.get("https://card.be-native.life:8443/issue_records", {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            });
            const newEditableNumIssued: { [key: string]: string } = {};
            const formattedData = response.data.map((record: any) => {
                newEditableNumIssued[record.user_id] = record.max_issue_num ? record.max_issue_num.toString() : '';
                return createData(record.display_name, record.user_id, record.num_issued, record.updated_at, record.num_left);
            });
            setEditableNumIssued(newEditableNumIssued);
            return formattedData;
        } catch (error) {
            console.error('Error fetching data: ', error);
            return [];
        }
    };

    const updateNumIssued = async (userId: string, username: string, password: string) => {
        const value = editableNumIssued[userId];
        const token = btoa(`${username}:${password}`);
        try {
            await axios.patch(`https://card.be-native.life:8443/issue_records`, {
                line_id: userId,
                arbitrary_issue_num: parseInt(value, 10)
            }, {
                headers: {
                    'Authorization': `Basic ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Error updating numIssued: ', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value);
        if (!value) {
            setRows(initialRows);
        } else {
            const filteredRows = initialRows.filter(row => row.displayName.toLowerCase().includes(value.toLowerCase()));
            setRows(filteredRows);
        }
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            const fetchedData = await fetchData(username, password);
            setData(fetchedData);
            setRows(fetchedData);
        }
    };

    const sortRows = (column: keyof Data) => {
        const newDirection = sortDirection.column === column && sortDirection.direction === 'asc' ? 'desc' : 'asc';
        const sortedRows = [...rows].sort((a, b) => {
            if (column === 'createdAt') {
                return a.createdAt.localeCompare(b.createdAt) * (newDirection === 'asc' ? 1 : -1);
            } else if (column === 'numIssued') {
                return (a.numIssued - b.numIssued) * (newDirection === 'asc' ? 1 : -1);
            }
            return 0;
        });
        setRows(sortedRows);
        setSortDirection({ column, direction: newDirection });
    };

    function downloadCSV() {
        const csvRows = [
            ['LINE Name', 'User ID', 'Num Issued', 'Created at'],
            ...rows.map(row => [row.displayName, row.userId, row.numIssued, new Date(row.createdAt).toISOString()]),
        ];

        const csvContent = csvRows.map(e => e.join(',')).join('\n');
        const utf8Bytes = new TextEncoder().encode(csvContent);
        const blob = new Blob([utf8Bytes], { type: 'text/csv;charset=utf-8' });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'lineuser-ticket-db.csv';
        a.click();
        URL.revokeObjectURL(url);
    }

    const handleFetchData = async () => {
        const fetchedData = await fetchData(username, password);
        setData(fetchedData);
        setRows(fetchedData);
    };

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Box width="90%">
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Grid container spacing={2} padding={2}>
                            <Grid xs={2}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    size="small"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    style={{ marginBottom: 8 }}
                                />
                            </Grid>
                            <Grid xs={2}>
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    size="small"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onKeyPress={handleLogin}
                                    style={{ marginBottom: 8 }}
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Button variant={"contained"} color={'inherit'} onClick={handleFetchData}>データを取得</Button>
                            </Grid>
                            <Grid xs={3}>
                                <TextField
                                    label="LINE名で検索"
                                    variant="outlined"
                                    size="small"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    style={{ flexGrow: 1, marginRight: 8 }} // 検索バーを横に広げる
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Button
                                    variant="contained"
                                    onClick={downloadCSV}
                                    style={{ backgroundColor: 'black', color: 'white', marginRight: 8 }} // マージン追加
                                    startIcon={<CloudDownloadIcon />}
                                >
                                    CSV
                                </Button>
                            </Grid>

                        </Grid>

                    </Box>
                    {rows.length !== 0 && <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">LINE名</TableCell>
                                    <TableCell align="center">User ID</TableCell>
                                    <TableCell align="center">
                                        発行枚数
                                        <IconButton size="small" onClick={() => sortRows('numIssued')}>
                                            <Sort />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        最終更新日時
                                        <IconButton size="small" onClick={() => sortRows('createdAt')}>
                                            <Sort />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">発行可能枚数</TableCell>
                                    <TableCell align="center">発行可能枚数を更新</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.userId}>
                                        <TableCell align="center">{row.displayName}</TableCell>
                                        <TableCell align="center">{row.userId}</TableCell>
                                        <TableCell align="center">{row.numIssued}</TableCell>
                                        <TableCell align="center">{row.createdAt}</TableCell>
                                        <TableCell align="center">{row.numLeft}</TableCell>
                                        <TableCell align="center">
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    size="small"
                                                    value={row.editableNumIssued ?? ''}
                                                    onChange={(e) => handleNumIssuedChange(row.userId, e.target.value)}
                                                    sx={{ width: '80px' }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="inherit"
                                                    size="small"
                                                    style={{ marginLeft: 10 }}
                                                    onClick={() => updateNumIssued(row.userId, username, password)}
                                                >枚数を更新</Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <TablePagination
                            rowsPerPageOptions={[50, 100, 500]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0); // Reset page to 0 when changing rows per page
                            }}
                        />
                    </TableContainer>}
                    {rows.length === 0 && <Box display="flex" justifyContent="center" mt={2}>
                        <Box>ユーザーIDおよびパスワードを入力して、データを取得ボタンを押してください。</Box>
                    </Box>}
                </Box>
            </Box>
        </>
    );
}
