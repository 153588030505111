import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import BasicTable from './components/TableConsole';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Nav />
            <BasicTable />
          </>
        } />
        <Route path="/T" element={
          <>login</>
        } />
      </Routes>
    </Router >
  );
}

export default App;
